header {
    height: 100vh;
    overflow: hidden;
    /* center content vertically */
    display: flex;
    align-items: center;
}

.header__container {
    text-align: center;
    position: relative;
    overflow: hidden;
}

/* ========= CTA ========= */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ========= ME ============ */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 22rem;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    overflow: hidden;
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ============ */
@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 670px) {
    header {
        height: 100vh;
    }
}